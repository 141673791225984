import { /* baseClient, */ authClient, API } from "../index";

// Create Log
export const postLog = (field, action, name) => {
  const body = { field, action, name };
  return authClient().post(`${API}/log`, body);
};

// Get all logs
export const getLogs = () => {
  return authClient().get(`${API}/log`);
};
