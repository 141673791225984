import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import Select from "react-select";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteAdvertiser,
  getAdvertiserById,
  postAdvertiser,
  updateAdvertiser,
} from "../../../../api/advertiser";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { getCustomers } from "../../../../api/customer";
import { shallowEqual, useSelector } from "react-redux";
import AuthorFooter from "../../../components/authorFooter";
import { getProfileById } from "../../../../api/profile/index";
import { postLog } from "../../../../api/log";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

function getEmptyAdvertiser() {
  return {
    fullName: "",
    customer: "",
    code: "",
  };
}

export default function EditAdvertisersPage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const [profile, setProfile] = useState(null);
  const [advertiser, setAdvertiser] = useState(getEmptyAdvertiser());
  const [customers, setCustomers] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const advertiserId = useParams().id;
  const history = useHistory();

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    getCustomers()
      .then((res) => {
        if (res.status === 200) {
          setCustomers(res.data);
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get customers." });
        history.push("/advertisers");
      });
    if (!advertiserId) {
      disableLoadingData();
      return;
    }
    getAdvertiserById(advertiserId)
      .then((res) => {
        if (res.status === 200) {
          postLog("advertiser-detail", "visualize", user.fullName);
          setAdvertiser(res.data);
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get advertiser.",
        });
        history.push("/advertisers");
      });
    if (user.profile) {
      getProfileById(user.profile).then((res) => {
        setProfile(res.data.permissions);
      });
    }
  }, [advertiserId, disableLoadingData, history]);

  function saveAdvertiser() {
    if (!advertiserId) {
      postAdvertiser(advertiser)
        .then((res) => {
          if (res.status === 201) {
            postLog("advertiser", "create", user.fullName);
            alertSuccess({
              title: "Saved!",
              customMessage: "Advertiser successfully created.",
            });
            history.push("/advertisers");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save advertiser.",
          });
        });
    } else {
      updateAdvertiser(advertiserId, advertiser)
        .then((res) => {
          if (res.status === 200) {
            postLog("advertiser", "update", user.fullName);
            alertSuccess({
              title: "Saved!",
              customMessage: "Changes successfully saved.",
            });
            history.push("/advertisers");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save changes.",
          });
        });
    }
  }

  const handleChange = (element) => (event) => {
    if (element === "customer") {
      const code = customers.find((item) => item._id === event._id).code;
      setAdvertiser({ ...advertiser, [element]: event._id, code: code + "." });
    } else setAdvertiser({ ...advertiser, [element]: event.target.value });
  };

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Edit advertiser"></CardHeader>
          <CardBody>
            {advertiserId && (
              <TextField
                id={`code`}
                label="Code"
                value={advertiser.code}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="outlined"
                disabled
              />
            )}
            <TextField
              id={`fullName`}
              label="Advertiser"
              value={advertiser.fullName}
              onChange={handleChange("fullName")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <span>Customer</span>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              className="my-2"
              defaultValue={
                advertiser.customer && customers
                  ? customers.find((item) => item._id === advertiser.customer)
                  : ""
              }
              onChange={handleChange("customer")}
              options={customers}
              getOptionLabel={(option) => `${option.code} - ${option.fullName}`}
              getOptionValue={(option) => `${option._id}`}
              placeholder="Select a customer..."
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
            />
            {advertiserId && (
              <AuthorFooter
                userAdded={advertiser.createdBy}
                dateCreated={advertiser.createdAt}
                userModified={advertiser.modifiedBy}
                dateModified={advertiser.updatedAt}
                entity="advertiser"
              />
            )}
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/advertisers")}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Back
        </Button>
        <Button
          onClick={() => saveAdvertiser()}
          variant="outlined"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Save advertiser
        </Button>
        {advertiserId && profile.includes("deleteAccess") && (
          <>
            <MuiThemeProvider theme={theme}>
              <Button
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
              >
                Delete advertiser
              </Button>
            </MuiThemeProvider>

            <ConfirmDialog
              title={"Are you sure you want to delete this advertiser?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteAdvertiser(advertiserId)
                  .then((res) => {
                    if (res.status === 204) {
                      alertSuccess({
                        title: "Deleted!",
                        customMessage: "Advertiser deleted successfully",
                      });
                      history.push("/advertisers");
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not delete advertiser.",
                    });
                  });
              }}
            />
          </>
        )}
      </>
    );
}
