import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import Select from "react-select";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteProduct,
  getProductById,
  postProduct,
  updateProduct,
} from "../../../../api/product";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { getAdvertisers } from "../../../../api/advertiser";
import { shallowEqual, useSelector } from "react-redux";
import AuthorFooter from "../../../components/authorFooter";
import { getProfileById } from "../../../../api/profile";
import { postLog } from "../../../../api/log";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

function getEmptyProduct() {
  return {
    fullName: "",
    advertiser: "",
    code: "",
  };
}

export default function EditProductsPage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const [profile, setProfile] = useState(null);
  const [product, setProduct] = useState(getEmptyProduct());
  const [advertisers, setAdvertisers] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const productId = useParams().id;
  const history = useHistory();

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    getAdvertisers()
      .then((res) => {
        if (res.status === 200) {
          setAdvertisers(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get advertisers.",
        });
        history.push("/products");
      });
    if (!productId) {
      disableLoadingData();
      return;
    }
    getProductById(productId)
      .then((res) => {
        if (res.status === 200) {
          postLog("product-detail", "visualize", user.fullName);
          const users = res.data;
          delete users.password;
          setProduct(users);
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get product." });
        history.push("/products");
      });
    if (user.profile) {
      getProfileById(user.profile).then((res) => {
        setProfile(res.data.permissions);
      });
    }
  }, [productId, disableLoadingData, history]);

  function saveProduct() {
    if (!productId) {
      postProduct(product)
        .then((res) => {
          if (res.status === 201) {
            postLog("product", "create", user.fullName);
            alertSuccess({
              title: "Saved!",
              customMessage: "Product successfully created.",
            });
            history.push("/products");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save product.",
          });
        });
    } else {
      updateProduct(productId, product)
        .then((res) => {
          if (res.status === 200) {
            postLog("product", "update", user.fullName);
            alertSuccess({
              title: "Saved!",
              customMessage: "Changes successfully saved.",
            });
            history.push("/products");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save changes.",
          });
        });
    }
  }

  const handleChange = (element) => (event) => {
    if (element === "advertiser") {
      const code = advertisers.find((item) => item._id === event._id).code;
      setProduct({ ...product, [element]: event._id, code: code + "." });
    } else setProduct({ ...product, [element]: event.target.value });
  };

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Edit product"></CardHeader>
          <CardBody>
            {productId && (
              <TextField
                id={`code`}
                label="Code"
                value={product.code}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="outlined"
                disabled
              />
            )}
            <TextField
              id={`fullName`}
              label="Product"
              value={product.fullName}
              onChange={handleChange("fullName")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <span>Advertiser</span>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              className="my-2"
              defaultValue={
                product.advertiser && advertisers
                  ? advertisers.find((item) => item._id === product.advertiser)
                  : ""
              }
              onChange={handleChange("advertiser")}
              options={advertisers}
              getOptionLabel={(option) => `${option.code} - ${option.fullName}`}
              getOptionValue={(option) => `${option._id}`}
              placeholder="Select an advertiser..."
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
            />
            {productId && (
              <AuthorFooter
                userAdded={product.createdBy}
                dateCreated={product.createdAt}
                userModified={product.modifiedBy}
                dateModified={product.updatedAt}
                entity="product"
              />
            )}
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/products")}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Back
        </Button>
        <Button
          onClick={() => saveProduct()}
          variant="outlined"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Save product
        </Button>
        {productId && profile.includes("deleteAccess") && (
          <>
            <MuiThemeProvider theme={theme}>
              <Button
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
              >
                Delete product
              </Button>
            </MuiThemeProvider>

            <ConfirmDialog
              title={"Are you sure you want to delete this product?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteProduct(productId)
                  .then((res) => {
                    if (res.status === 204) {
                      postLog("product", "delete", user.fullName);
                      alertSuccess({
                        title: "Deleted!",
                        customMessage: "Product deleted successfully",
                      });
                      history.push("/products");
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not delete product.",
                    });
                  });
              }}
            />
          </>
        )}
      </>
    );
}
