import React from 'react'
// import { Dashboard } from "../../_metronic/_partials";

export function DashboardPage() {
	return (
		<div>
			<h2>Welcome to the medialog project management space</h2>
			<p>This space is intended for the generation of project codes</p>
		</div>
	)
}
