import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteCustomer,
  getCustomerById,
  postCustomer,
  updateCustomer,
} from "../../../../api/customer";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import AuthorFooter from "../../../components/authorFooter";
import { shallowEqual, useSelector } from "react-redux";
import { getProfileById } from "../../../../api/profile";
import { postLog } from "../../../../api/log";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

function getEmptyCustomer() {
  return {
    fullName: "",
    code: "",
  };
}

export default function EditCustomersPage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const [customer, setCustomer] = useState(getEmptyCustomer());
  const [profile, setProfile] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const customerId = useParams().id;
  const history = useHistory();

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    if (!customerId) {
      disableLoadingData();
      return;
    }
    getCustomerById(customerId)
      .then((res) => {
        if (res.status === 200) {
          postLog("customer-detail", "visualize", user.fullName);
          setCustomer(res.data);
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get customer." });
        history.push("/customers");
      });
    if (user.profile) {
      getProfileById(user.profile).then((res) => {
        setProfile(res.data.permissions);
      });
    }
  }, [customerId, disableLoadingData, history]);

  function saveCustomer() {
    if (!customerId) {
      postCustomer(customer)
        .then((res) => {
          if (res.status === 201) {
            postLog("customer", "create", user.fullName);
            alertSuccess({
              title: "Saved!",
              customMessage: "Customer successfully created.",
            });
            history.push("/customers");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save customer.",
          });
        });
    } else {
      updateCustomer(customerId, customer)
        .then((res) => {
          if (res.status === 200) {
            postLog("customer", "update", user.fullName);
            alertSuccess({
              title: "Saved!",
              customMessage: "Changes successfully saved.",
            });
            history.push("/customers");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save changes.",
          });
        });
    }
  }

  const handleChange = (element) => (event) => {
    setCustomer({ ...customer, [element]: event.target.value });
  };

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Edit customer"></CardHeader>
          <CardBody>
            {customerId && (
              <TextField
                id={`code`}
                label="Code"
                value={customer.code}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="outlined"
                disabled
              />
            )}
            <TextField
              id={`fullName`}
              label="Customer"
              value={customer.fullName}
              onChange={handleChange("fullName")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            {customerId && (
              <AuthorFooter
                userAdded={customer.createdBy}
                dateCreated={customer.createdAt}
                userModified={customer.modifiedBy}
                dateModified={customer.updatedAt}
                entity="customer"
              />
            )}
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/customers")}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Back
        </Button>
        <Button
          onClick={() => saveCustomer()}
          variant="outlined"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Save customer
        </Button>
        {customerId && profile.includes("deleteAccess") && (
          <>
            <MuiThemeProvider theme={theme}>
              <Button
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
              >
                Delete customer
              </Button>
            </MuiThemeProvider>

            <ConfirmDialog
              title={"Are you sure you want to delete this customer?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteCustomer(customerId)
                  .then((res) => {
                    if (res.status === 204) {
                      postLog("customer", "delete", user.fullName);
                      alertSuccess({
                        title: "Deleted!",
                        customMessage: "Customer deleted successfully",
                      });
                      history.push("/customers");
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not delete customer.",
                    });
                  });
              }}
            />
          </>
        )}
      </>
    );
}
