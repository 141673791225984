import { /* baseClient, */ authClient, API } from '../index'

// Get all campaigns
export const getCampaigns = () => {
  return authClient().get(`${API}/campaign`)
}

// Get campaign by id
export const getCampaignById = (id) => {
  return authClient().get(`${API}/campaign/${id}`)
}

// Delete campaign
export const deleteCampaign = (id) => {
  return authClient().delete(`${API}/campaign/${id}`)
}

// Create campaign
export const postCampaign = (campaign) => {
  return authClient().post(`${API}/campaign`, campaign)
}

// Update campaign
export const updateCampaign = (id, campaign) => {
  return authClient().put(`${API}/campaign/${id}`, campaign)
}
