import { /* baseClient, */ authClient, API } from '../index'

// Get all profiles
export const getProfiles = () => {
  return authClient().get(`${API}/profile`)
}

// Get profile by id
export const getProfileById = (id) => {
  return authClient().get(`${API}/profile/${id}`)
}

// Delete profile
export const deleteProfile = (id) => {
  return authClient().delete(`${API}/profile/${id}`)
}

// Create profile
export const postProfile = (profile) => {
  return authClient().post(`${API}/profile`, profile)
}

// Update profile
export const updateProfile = (id, profile) => {
  return authClient().put(`${API}/profile/${id}`, profile)
}