import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
  Tooltip,
} from "@material-ui/core";
import Select from "react-select";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteCampaign,
  getCampaignById,
  postCampaign,
  updateCampaign,
} from "../../../../api/campaign";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { getProducts } from "../../../../api/product";
import { getAdvertisers } from "../../../../api/advertiser";
import { getCustomers } from "../../../../api/customer";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { shallowEqual, useSelector } from "react-redux";
import AuthorFooter from "../../../components/authorFooter";
import { getProfileById } from "../../../../api/profile";
import { postLog } from "../../../../api/log";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

function getEmptyCampaign() {
  return {
    fullName: "",
    year: new Date().getFullYear(),
    product: "",
    code: "",
  };
}

export default function EditCampaignsPage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const [campaign, setCampaign] = useState(getEmptyCampaign());
  const [profile, setProfile] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [advertisers, setAdvertisers] = useState(null);
  const [products, setProducts] = useState(null);
  const [selectedAdvertiser, setSelectedAdvertiser] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [code, setCode] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const campaignId = useParams().id;
  const history = useHistory();

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    getProducts()
      .then((res) => {
        if (res.status === 200) {
          setProducts(res.data);
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get products." });
        history.push("/campaigns");
      });
    getAdvertisers()
      .then((res) => {
        if (res.status === 200) {
          setAdvertisers(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get advertisers.",
        });
        history.push("/campaigns");
      });
    getCustomers()
      .then((res) => {
        if (res.status === 200) {
          setCustomers(res.data);
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get customers." });
        history.push("/campaigns");
      });
    if (!campaignId) {
      disableLoadingData();
      return;
    }
    getCampaignById(campaignId)
      .then((res) => {
        if (res.status === 200) {
          postLog("campaign-detail", "visualize", user.fullName);
          setCampaign(res.data);
          disableLoadingData();
          setSelectedAdvertiser(res.data.additionalData?.advertiser);
          setSelectedCustomer(res.data.additionalData?.customer);
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get campaign." });
        history.push("/campaigns");
      });
    if (user.profile) {
      getProfileById(user.profile).then((res) => {
        setProfile(res.data.permissions);
      });
    }
  }, [campaignId, disableLoadingData, history]);

  function saveCampaign() {
    if (campaign.additionalData) delete campaign.additionalData;
    if (!campaignId) {
      postCampaign({ ...campaign, year: campaign.year.toString() })
        .then((res) => {
          if (res.status === 201) {
            postLog("campaign", "create", user.fullName);
            navigator.clipboard.writeText(res.data.code);
            alertSuccess({
              title: "Saved!",
              customMessage: "Campaign successfully created.",
            });
            history.push("/campaigns");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save campaign.",
          });
        });
    } else {
      updateCampaign(campaignId, {
        ...campaign,
        year: campaign.year.toString(),
      })
        .then((res) => {
          if (res.status === 200) {
            postLog("campaign", "update", user.fullName);
            alertSuccess({
              title: "Saved!",
              customMessage: "Changes successfully saved.",
            });
            history.push("/campaigns");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save changes.",
          });
        });
    }
  }

  const handleChange = (element) => (event) => {
    if (element === "year") {
      const year = event.target.value;
      if (year < 0 || year.length > 4 || year === " ") return;
      setCampaign({ ...campaign, [element]: year });
    } else setCampaign({ ...campaign, [element]: event.target.value });
  };

  if (isLoadingData) return <ContentSkeleton />;
  else
    return (
      <>
        <Card>
          <CardHeader title="Edit campaign"></CardHeader>
          <CardBody>
            <TextField
              id={`year`}
              label="Year"
              value={campaign.year}
              onChange={handleChange("year")}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { min: 0, max: 9999, pattern: "[0-9]" },
              }}
              type="number"
              margin="normal"
              variant="outlined"
              required
            />
            <TextField
              id={`fullName`}
              label="Campaign"
              value={campaign.fullName}
              onChange={handleChange("fullName")}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              variant="outlined"
              required
            />
            <span>Customer</span>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              className="my-2"
              defaultValue={selectedCustomer || ""}
              onChange={(event) => {
                setSelectedAdvertiser(null);
                setSelectedProduct(null);
                setCampaign({ ...campaign, product: "" });
                setSelectedCustomer(event);
                setCode(event.code + ".");
              }}
              options={customers}
              getOptionLabel={(option) => `${option.code} - ${option.fullName}`}
              getOptionValue={(option) => `${option._id}`}
              placeholder="Select a customer..."
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
            />
            {selectedCustomer && (
              <>
                <span>Advertiser</span>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  className="my-2"
                  defaultValue={selectedAdvertiser || ""}
                  onChange={(event) => {
                    setCampaign({ ...campaign, product: "" });
                    setSelectedProduct(null);
                    setSelectedAdvertiser(event);
                    setCode(event.code + ".");
                  }}
                  options={advertisers?.filter(
                    (item) => item.customer === selectedCustomer._id
                  )}
                  getOptionLabel={(option) =>
                    `${option.code} - ${option.fullName}`
                  }
                  getOptionValue={(option) => `${option._id}`}
                  placeholder="Select an advertiser..."
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                />
              </>
            )}
            {selectedAdvertiser && selectedCustomer && (
              <>
                <span>Product</span>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  className="my-2"
                  defaultValue={
                    selectedProduct ||
                    (campaign.product && products
                      ? products.find((item) => item._id === campaign.product)
                      : "")
                  }
                  onChange={(event) => {
                    setCampaign({
                      ...campaign,
                      product: event._id,
                      code: event.code + ".",
                    });
                    setSelectedProduct(event);
                    setCode(event.code + ".");
                  }}
                  options={products?.filter(
                    (item) => item.advertiser === selectedAdvertiser._id
                  )}
                  getOptionLabel={(option) =>
                    `${option.code} - ${option.fullName}`
                  }
                  getOptionValue={(option) => `${option._id}`}
                  placeholder="Select a product..."
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                />
              </>
            )}
            <div className="row mt-5" style={{ margin: "auto" }}>
              <TextField
                id={`code`}
                label="Code"
                className="col-10 col-lg-11"
                value={code || campaign.code}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
                margin="normal"
                variant="outlined"
              />
              <Tooltip title="Copy code">
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(campaign.code);
                    alertSuccess({
                      title: "Done!",
                      customMessage:
                        "Campaing code successfully copied to clipboard.",
                    });
                  }}
                >
                  <FileCopyIcon />
                </Button>
              </Tooltip>
            </div>
            {campaignId && (
              <AuthorFooter
                userAdded={campaign.createdBy}
                dateCreated={campaign.createdAt}
                userModified={campaign.modifiedBy}
                dateModified={campaign.updatedAt}
                entity="campaign"
              />
            )}
          </CardBody>
        </Card>
        <Button
          onClick={() => history.push("/campaigns")}
          variant="outlined"
          style={{ marginRight: "20px" }}
        >
          Back
        </Button>
        <Button
          onClick={() => saveCampaign()}
          variant="outlined"
          color="primary"
          style={{ marginRight: "20px" }}
        >
          Save campaign
        </Button>
        {campaignId && profile.includes("deleteAccess") && (
          <>
            <MuiThemeProvider theme={theme}>
              <Button
                onClick={() => setOpenConfirmDialog(true)}
                variant="outlined"
                color="secondary"
              >
                Delete campaign
              </Button>
            </MuiThemeProvider>

            <ConfirmDialog
              title={"Are you sure you want to delete this campaign?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteCampaign(campaignId)
                  .then((res) => {
                    if (res.status === 204) {
                      postLog("campaign", "delete", user.fullName);
                      alertSuccess({
                        title: "Deleted!",
                        customMessage: "Campaign deleted successfully",
                      });
                      history.push("/campaigns");
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not delete campaign.",
                    });
                  });
              }}
            />
          </>
        )}
      </>
    );
}
