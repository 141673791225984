import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table, {
  dateFormatter,
  buttonsStyle,
} from "../../../components/tables/table";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { getCampaigns, deleteCampaign } from "../../../../api/campaign";
import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";
import { getProducts } from "../../../../api/product";
import { shallowEqual, useSelector } from "react-redux";
import { getCustomers } from "../../../../api/customer";
import { getAdvertisers } from "../../../../api/advertiser";
import replaceSpecialCharacters from "replace-special-characters";
import { getProfileById } from "../../../../api/profile";
import { postLog } from "../../../../api/log";

function getData(campaigns, products, customers, advertisers) {
  let data = [];
  for (let i = 0; i < campaigns.length; ++i) {
    const elem = {};
    const product = products.find((cat) => cat._id === campaigns[i].product);
    const advertiser = product
      ? advertisers.find((cat) => cat._id === product.advertiser)
      : null;
    const customer = advertiser
      ? customers.find((cat) => cat._id === advertiser.customer)
      : null;
    elem.name = campaigns[i].fullName;
    elem.code = campaigns[i].code;
    elem.year = campaigns[i].year;
    elem.product = product ? product.fullName : "---";
    elem.advertiser = advertiser ? advertiser.fullName : "---";
    elem.customer = customer ? customer.fullName : "---";
    elem.createdAt = campaigns[i].createdAt;
    elem.id = campaigns[i]._id;
    elem.key = { id: campaigns[i]._id, code: campaigns[i].code };
    data = data.concat(elem);
  }
  return data;
}

export default function CampaignsPage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const [data, setData] = useState([]);
  const [profile, setProfile] = useState("");
  const [campaignId, setCampaignId] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const history = useHistory();

  const copyToClipboard = (code) => {
    navigator.clipboard.writeText(code);
    alertSuccess({
      title: "Done!",
      customMessage: "Campaing code successfully copied to clipboard.",
    });
  };

  function buttonFormatter(cell) {
    let deleteButton;
    if (user?.type === "admin" && profile === "Administrador")
      deleteButton = (
        <Tooltip title="Delete">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              setCampaignId(cell.id);
              setOpenConfirmDialog(true);
            }}
          >
            <DeleteIcon />
          </Button>
        </Tooltip>
      );

    return (
      <>
        <Tooltip title="Copy code">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => copyToClipboard(cell.code)}
          >
            <FileCopyIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Edit">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => history.push("/edit-campaign/" + cell.id)}
          >
            <EditIcon />
          </Button>
        </Tooltip>
        {deleteButton}
      </>
    );
  }

  const columns = [
    {
      dataField: "code",
      text: "Code",
      sort: true,
      style: { cursor: "copy" },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) =>
          copyToClipboard(row.code),
      },
    },
    { dataField: "name", text: "Campaign", sort: true },
    { dataField: "year", text: "Year", sort: true },
    { dataField: "product", text: "Product", sort: true },
    { dataField: "advertiser", text: "Advertiser", sort: true },
    { dataField: "customer", text: "Customer", sort: true },
    {
      dataField: "createdAt",
      text: "Created at",
      formatter: dateFormatter,
      sort: true,
    },
    {
      dataField: "key",
      text: "",
      formatter: buttonFormatter,
      searchable: false,
    },
  ];
  const defaultSorted = [
    {
      dataField: "createdAt",
      order: "desc",
    },
  ];

  useEffect(() => {
    getCampaigns()
      .then((res) => {
        if (res.status === 200) {
          getProducts()
            .then((resp) => {
              if (resp.status === 200) {
                getCustomers()
                  .then((respCust) => {
                    if (respCust.status === 200) {
                      getAdvertisers()
                        .then((respAdv) => {
                          if (respAdv.status === 200) {
                            postLog(
                              "campaign-list",
                              "visualize",
                              user.fullName
                            );
                            setData(
                              getData(
                                res.data,
                                resp.data,
                                respCust.data,
                                respAdv.data
                              )
                            );
                            setRefresh(false);
                          }
                        })
                        .catch((error) => {
                          alertError({
                            error: error,
                            customMessage: "Could not get customers.",
                          });
                        });
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not get customers.",
                    });
                  });
              }
            })
            .catch((error) => {
              alertError({
                error: error,
                customMessage: "Could not get products.",
              });
            });
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get campaigns." });
      });
    if (user.profile) {
      getProfileById(user?.profile)
        .then((res) => {
          if (res.status === 200) {
            setProfile(res.data.type);
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not get user profile.",
          });
        });
    }
  }, [refresh]);

  function exportToCsv(filename, rows) {
    let csvFile = "";
    csvFile += `${[
      "CUSTOMER",
      "ADVERTISER",
      "PRODUCT",
      "CAMPAIGN",
      "YEAR",
      "REFERENCE",
    ].join(";")}\r\n`;

    rows.forEach((row) => {
      const properValues = [
        replaceSpecialCharacters(row.customer),
        replaceSpecialCharacters(row.advertiser),
        replaceSpecialCharacters(row.product),
        replaceSpecialCharacters(row.name),
        row.year,
        row.code,
      ];
      return (csvFile += `${properValues.join(";")}\r\n`);
    });

    const blob = new Blob([csvFile], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  return (
    <>
      <Card>
        <CardHeader title="Campaigns list">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={() => {
                let today = new Date();
                const dd = String(today.getDate()).padStart(2, "0");
                const mm = String(today.getMonth() + 1).padStart(2, "0");
                const yyyy = today.getFullYear();
                today = dd + "-" + mm + "-" + yyyy;
                exportToCsv("Campaigns_" + today, data);
              }}
            >
              Export excel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => history.push("/edit-campaign")}
            >
              Add new
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table
            data={data}
            columns={columns}
            yearFilter={true}
            defaultSorted={defaultSorted}
          />
          <ConfirmDialog
            title={"Are you sure you want to remove this campaign?"}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteCampaign(campaignId)
                .then((res) => {
                  if (res.status === 204) {
                    postLog("campaign-list", "delete", user.fullName);
                    alertSuccess({
                      title: "Deleted!",
                      customMessage: "Campaign removed successfully.",
                    });
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: "Could not remove campaign.",
                  });
                });
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
