/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import CastIcon from "@material-ui/icons/Cast";
import BusinessIcon from "@material-ui/icons/Business";
import ExtensionIcon from "@material-ui/icons/Extension";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AssignmentIcon from "@material-ui/icons/Assignment";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import UpdateIcon from "@material-ui/icons/Update";
import { shallowEqual, useSelector } from "react-redux";
import { getProfileById } from "../../../../../api/profile";
import { alertError } from "../../../../../utils/logger";

export function AsideMenuList({ layoutProps }) {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const [profile, setProfile] = React.useState([]);
  const [adminMenu, setAdminMenu] = React.useState(<></>);
  const [excelTable, setExcelTable] = React.useState(<></>);
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  React.useEffect(() => {
    let isMounted = true;
    const obtainProf = async () => {
      if (user.profile) {
        getProfileById(user?.profile)
          .then((res) => {
            if (res.status === 200) {
              setProfile(res.data.permissions);
            }
          })
          .catch((error) => {
            alertError({
              error: error,
              customMessage: "Could not get user profile.",
            });
          });
      }
    };
    obtainProf();
    return () => {
      isMounted = false;
    };
  }, []);

  React.useEffect(() => {
    setAdminMenu(getAdminMenu());
    setExcelTable(getExcelTable());
  }, [profile]);

  const getAdminMenu = () => {
    if (profile.includes("adminAccess")) {
      return (
        <>
          <li
            className={`menu-item ${getMenuItemActive(
              "/admins",
              false
            )} ${getMenuItemActive("/edit-admin", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/admins">
              <span className="menu-icon" style={{ color: "black" }}>
                <SupervisorAccountIcon />
              </span>
              <span className="menu-text">Users</span>
            </NavLink>
          </li>
          <li
            className={`menu-item ${getMenuItemActive(
              "/profiles",
              false
            )} ${getMenuItemActive("/edit-profile", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/profiles">
              <span className="menu-icon" style={{ color: "black" }}>
                <AssignmentIcon />
              </span>
              <span className="menu-text">Profiles</span>
            </NavLink>
          </li>
          <li
            className={`menu-item ${getMenuItemActive("/logs", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/logs">
              <span className="menu-icon" style={{ color: "black" }}>
                <FormatListBulletedIcon />
              </span>
              <span className="menu-text">Logs</span>
            </NavLink>
          </li>
          <li
            className={`menu-item ${getMenuItemActive("/updates", false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/updates">
              <span className="menu-icon">
                <UpdateIcon />
              </span>
              <span className="menu-text">Actualizaciones</span>
            </NavLink>
          </li>
        </>
      );
    }
  };

  const getExcelTable = () => {
    if (profile.includes("excelAccess"))
      return (
        <li
          className={`menu-item ${getMenuItemActive("/datatable", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/datatable">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Datatable</span>
          </NavLink>
        </li>
      );
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive(
            "/campaigns",
            false
          )} ${getMenuItemActive("/edit-campaign", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/campaigns">
            <span className="menu-icon" style={{ color: "black" }}>
              <CastIcon />
            </span>
            <span className="menu-text">Campaign</span>
          </NavLink>
        </li>
        <li className="menu-section">
          <h4 className="menu-text">Entities</h4>
          <i className="menu-icon ki ki-bold-more-hor icon-md"></i>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/customers",
            false
          )} ${getMenuItemActive("/edit-customer", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/customers">
            <span className="menu-icon" style={{ color: "black" }}>
              <BusinessIcon />
            </span>
            <span className="menu-text">Customer</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/advertisers",
            false
          )} ${getMenuItemActive("/edit-advertiser", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/advertisers">
            <span className="menu-icon" style={{ color: "black" }}>
              <VolumeUpIcon />
            </span>
            <span className="menu-text">Advertiser</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/products",
            false
          )} ${getMenuItemActive("/edit-product", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/products">
            <span className="menu-icon" style={{ color: "black" }}>
              <ExtensionIcon />
            </span>
            <span className="menu-text">Product</span>
          </NavLink>
        </li>
        {excelTable}
        {adminMenu}
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
