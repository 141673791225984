import { /* baseClient, */ authClient, API } from '../index'

// Get all products
export const getProducts = () => {
  return authClient().get(`${API}/product`)
}

// Get product by id
export const getProductById = (id) => {
  return authClient().get(`${API}/product/${id}`)
}

// Delete product
export const deleteProduct = (id) => {
  return authClient().delete(`${API}/product/${id}`)
}

// Create product
export const postProduct = (product) => {
  return authClient().post(`${API}/product`, product)
}

// Update product
export const updateProduct = (id, product) => {
  return authClient().put(`${API}/product/${id}`, product)
}
