import React from 'react'
import clsx from 'clsx'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import FullEditor from 'ckeditor5-build-full'

import { configuration } from './editorConfiguration'
import './editor.css'

function Editor({ body, setBody, placeholder = '', disabled = false, className, lang = 'en', label = '' }) {
	return (
		<div className={clsx('custom-editor', className, disabled && 'editor-disabled')}>
			<label>{label}</label>
			<CKEditor
				editor={ FullEditor }
				config={{ placeholder, ...configuration, language: lang }}
				data={body}
				className={disabled && 'editor-disabled'}
				onChange={ ( event, editor ) => {
					const data = editor.getData()
					setBody(data)
				} }
				disabled={disabled}
			/>
		</div>
	)

}

export default Editor