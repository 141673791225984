import React from "react";
import { Redirect, Switch } from "react-router-dom";
import { ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import AdminsPage from "./modules/ContentManager/admins/AdminsPage";
import EditAdminsPage from "./modules/ContentManager/admins/EditAdminsPage";
import CampaignsPage from "./modules/ContentManager/campaigns/CampaignsPage";
import EditCampaignsPage from "./modules/ContentManager/campaigns/EditCampaignsPage";
import CustomersPage from "./modules/ContentManager/customers/CustomersPage";
import EditCustomersPage from "./modules/ContentManager/customers/EditCustomersPage";
import AdvertisersPage from "./modules/ContentManager/advertisers/AdvertisersPage";
import EditAdvertisersPage from "./modules/ContentManager/advertisers/EditAdvertisersPage";
import ProductsPage from "./modules/ContentManager/products/ProductsPage";
import EditProductsPage from "./modules/ContentManager/products/EditProductsPage";
import DataTable from "./modules/ContentManager/dataTable/DataTable";
import ProfilesPage from "./modules/ContentManager/profiles/ProfilesPage";
import EditProfilesPage from "./modules/ContentManager/profiles/EditProfilesPage";
import LogsPage from "./modules/ContentManager/logs/LogsPage";
import UpdatesPage from "./modules/ContentManager/updates/UpdatesPage";

export default function BasePage() {
  return (
    <Switch>
      {/* Redirect from root URL to /dashboard. */}
      <Redirect exact from="/" to="/dashboard" />
      <ContentRoute path="/dashboard" component={DashboardPage} />
      {/* Administrators */}
      <ContentRoute from="/admins" component={AdminsPage} />
      <ContentRoute from="/edit-admin/:id?" component={EditAdminsPage} />
      {/* Campaigns */}
      <ContentRoute from="/campaigns" component={CampaignsPage} />
      <ContentRoute from="/edit-campaign/:id?" component={EditCampaignsPage} />
      {/* Customers */}
      <ContentRoute from="/customers" component={CustomersPage} />
      <ContentRoute from="/edit-customer/:id?" component={EditCustomersPage} />
      {/* Advertisers */}
      <ContentRoute from="/advertisers" component={AdvertisersPage} />
      <ContentRoute
        from="/edit-advertiser/:id?"
        component={EditAdvertisersPage}
      />
      {/* Products */}
      <ContentRoute from="/products" component={ProductsPage} />
      <ContentRoute from="/edit-product/:id?" component={EditProductsPage} />
      {/* DataTable */}
      <ContentRoute from="/datatable" component={DataTable} />
      {/* Profiles */}
      <ContentRoute from="/profiles" component={ProfilesPage} />
      <ContentRoute from="/edit-profile/:id?" component={EditProfilesPage} />
      {/* Logs */}
      <ContentRoute from="/logs" component={LogsPage} />
      {/* Updates */}
      <ContentRoute from="/updates" component={UpdatesPage} />
    </Switch>
  );
}
