import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table, {
  dateFormatter,
  buttonsStyle,
} from "../../../components/tables/table";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { getProducts, deleteProduct } from "../../../../api/product";
import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";
import { getAdvertisers } from "../../../../api/advertiser";
import { getCustomers } from "../../../../api/customer";
import { shallowEqual, useSelector } from "react-redux";
import { getProfileById } from "../../../../api/profile";
import { postLog } from "../../../../api/log";

function getData(products, advertisers, customers) {
  let data = [];
  for (let i = 0; i < products.length; ++i) {
    const elem = {};
    const advertiser = advertisers.find(
      (cat) => cat._id === products[i].advertiser
    );
    const customer = advertiser
      ? customers.find((cat) => cat._id === advertiser.customer)
      : null;
    elem.name = products[i].fullName;
    elem.code = products[i].code;
    elem.customer = customer ? customer.fullName : "---";
    elem.advertiser = advertiser ? advertiser.fullName : "---";
    elem.createdAt = products[i].createdAt;
    elem.id = products[i]._id;
    data = data.concat(elem);
  }
  return data;
}

export default function ProductsPage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const [data, setData] = useState([]);
  const [productId, setProductId] = useState(null);
  const [profile, setProfile] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const history = useHistory();

  function buttonFormatter(cell) {
    let deleteButton;
    if (user?.type === "admin" && profile === "Administrador")
      deleteButton = (
        <Tooltip title="Delete">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              setProductId(cell);
              setOpenConfirmDialog(true);
            }}
          >
            <DeleteIcon />
          </Button>
        </Tooltip>
      );
    return (
      <>
        <Tooltip title="Edit">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => history.push("/edit-product/" + cell)}
          >
            <EditIcon />
          </Button>
        </Tooltip>
        {deleteButton}
      </>
    );
  }

  const columns = [
    { dataField: "code", text: "Code", sort: true },
    { dataField: "name", text: "Product", sort: true },
    { dataField: "advertiser", text: "Advertiser", sort: true },
    { dataField: "customer", text: "Customer", sort: true },
    {
      dataField: "createdAt",
      text: "Created at",
      formatter: dateFormatter,
      sort: true,
    },
    {
      dataField: "id",
      text: "",
      formatter: buttonFormatter,
      searchable: false,
    },
  ];

  useEffect(() => {
    getProducts()
      .then((res) => {
        if (res.status === 200) {
          getAdvertisers()
            .then((resp) => {
              if (resp.status === 200) {
                getCustomers()
                  .then((respcust) => {
                    if (respcust.status === 200) {
                      postLog("product-list", "visualize", user.fullName);
                      setData(getData(res.data, resp.data, respcust.data));
                      setRefresh(false);
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not get customers.",
                    });
                  });
              }
            })
            .catch((error) => {
              alertError({
                error: error,
                customMessage: "Could not get advertisers.",
              });
            });
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get products." });
      });
    if (user.profile) {
      getProfileById(user?.profile)
        .then((res) => {
          if (res.status === 200) {
            setProfile(res.data.type);
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not get user profile.",
          });
        });
    }
  }, [refresh]);

  return (
    <>
      <Card>
        <CardHeader title="Products list">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => history.push("/edit-product")}
            >
              Add new
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table data={data} columns={columns} />
          <ConfirmDialog
            title={"Are you sure you want to remove this product?"}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteProduct(productId)
                .then((res) => {
                  if (res.status === 204) {
                    postLog("product", "delete", user.fullName);
                    alertSuccess({
                      title: "Deleted!",
                      customMessage: "Product removed successfully.",
                    });
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: "Could not remove product.",
                  });
                });
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
