import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table, {
  dateFormatter,
  buttonsStyle,
} from "../../../components/tables/table";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { getUsers, deleteUser } from "../../../../api/user";
import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { getProfileById } from "../../../../api/profile";

async function getData(admins) {
  let data = [];
  for (let i = 0; i < admins.length; ++i) {
    if (admins[i].role === "admin") {
      const elem = {};
      elem.name = admins[i].fullName;
      if (admins[i].profile) {
        elem.profile = await getProfileById(admins[i].profile);
        elem.profile = elem.profile.data.type;
      }
      elem.email = admins[i].email;
      elem.createdAt = admins[i].createdAt;
      elem.id = admins[i]._id;
      data = data.concat(elem);
    }
  }
  return data;
}

export default function AdminsPage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const [profile, setProfile] = useState("");
  const [data, setData] = useState([]);
  const [adminId, setAdminId] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const history = useHistory();

  function buttonFormatter(cell) {
    return (
      <>
        <Tooltip title="Edit">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => history.push("/edit-admin/" + cell)}
          >
            <EditIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Delete">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              setAdminId(cell);
              setOpenConfirmDialog(true);
            }}
          >
            <DeleteIcon />
          </Button>
        </Tooltip>
      </>
    );
  }

  const columns = [
    { dataField: "name", text: "User", sort: true },
    { dataField: "profile", text: "Profile", sort: true },
    { dataField: "email", text: "Email", sort: true },
    {
      dataField: "createdAt",
      text: "Created at",
      formatter: dateFormatter,
      sort: true,
    },
    {
      dataField: "id",
      text: "",
      formatter: buttonFormatter,
      searchable: false,
    },
  ];

  useEffect(() => {
    getUsers()
      .then((res) => {
        if (res.status === 200) {
          getData(res.data).then((resp) => {
            setData(resp);
          });
          setRefresh(false);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get users.",
        });
      });
    if (user.profile) {
      getProfileById(user?.profile)
        .then((res) => {
          if (res.status === 200) {
            setProfile(res.data.permissions);
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not get user profile.",
          });
        });
    }
  }, [refresh]);

  if (profile.includes("adminAccess"))
    return (
      <>
        <Card>
          <CardHeader title="Users list">
            <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => history.push("/edit-admin")}
              >
                Add new
              </button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            <Table data={data} columns={columns} />
            <ConfirmDialog
              title={"Are you sure you want to remove this usuari?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteUser(adminId)
                  .then((res) => {
                    if (res.status === 204) {
                      alertSuccess({
                        title: "Deleted!",
                        customMessage: "User removed successfully.",
                      });
                      setRefresh(true);
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not remove User.",
                    });
                  });
              }}
            />
          </CardBody>
        </Card>
      </>
    );
  else
    return (
      <>
        Sorry, you do not have access permissions. Ask an administrator to
        access this section
      </>
    );
}
