import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table, {
  dateFormatter,
  buttonsStyle,
} from "../../../components/tables/table";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import {
  getProfiles,
  deleteProfile,
  getProfileById,
} from "../../../../api/profile";
import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

function getData(profiles) {
  let data = [];
  for (let i = 0; i < profiles.length; ++i) {
    const elem = {};
    elem.type = profiles[i].type;
    elem.description = profiles[i].description;
    elem.createdAt = profiles[i].createdAt;
    elem.id = profiles[i]._id;
    data = data.concat(elem);
  }
  return data;
}

export default function ProfilesPage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const [data, setData] = useState([]);
  const [userProfile, setUserProfile] = useState("");
  const [profileId, setProfileId] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const history = useHistory();

  function buttonFormatter(cell) {
    return (
      <>
        <Tooltip title="Edit">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => history.push("/edit-profile/" + cell)}
          >
            <EditIcon />
          </Button>
        </Tooltip>
        <Tooltip title="Delete">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              setProfileId(cell);
              setOpenConfirmDialog(true);
            }}
          >
            <DeleteIcon />
          </Button>
        </Tooltip>
      </>
    );
  }

  const columns = [
    { dataField: "type", text: "Type", sort: true },
    { dataField: "description", text: "Description", sort: true },
    {
      dataField: "createdAt",
      text: "Created at",
      formatter: dateFormatter,
      sort: true,
    },
    {
      dataField: "id",
      text: "",
      formatter: buttonFormatter,
      searchable: false,
    },
  ];

  useEffect(() => {
    getProfiles()
      .then((res) => {
        if (res.status === 200) {
          setData(getData(res.data));
          setRefresh(false);
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get Profiles" });
      });
    if (user.profile) {
      getProfileById(user.profile).then((res) => {
        setUserProfile(res.data.permissions);
      });
    }
  }, [refresh]);

  if (userProfile.includes("adminAccess"))
    return (
      <>
        <Card>
          <CardHeader title="Profiles list">
            <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => history.push("/edit-profile")}
              >
                Add new
              </button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            <Table data={data} columns={columns} />
            <ConfirmDialog
              title={"Are you sure you want to remove this profile?"}
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              onConfirm={() => {
                deleteProfile(profileId)
                  .then((res) => {
                    if (res.status === 204) {
                      alertSuccess({
                        title: "Deleted!",
                        customMessage: "Profile removed successfully.",
                      });
                      setRefresh(true);
                    }
                  })
                  .catch((error) => {
                    alertError({
                      error: error,
                      customMessage: "Could not remove profile.",
                    });
                  });
              }}
            />
          </CardBody>
        </Card>
      </>
    );
  else
    return (
      <>
        Sorry, you do not have access permissions. Ask an administrator to
        access this section
      </>
    );
}
