import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import Editor from "../../../components/editor/Editor";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  TextareaAutosize,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteProfile,
  getProfileById,
  postProfile,
  updateProfile,
} from "../../../../api/profile";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { shallowEqual, useSelector } from "react-redux";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
};

function getEmptyProfile() {
  return {
    type: "",
    description: "",
    permissions: [],
  };
}

export default function EditProfilesPage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const [profile, setProfile] = useState(getEmptyProfile());
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const profileId = useParams().id;
  const history = useHistory();

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    if (!profileId) {
      disableLoadingData();
      return;
    }
    getProfileById(profileId)
      .then((res) => {
        if (res.status === 200) {
          const profile = res.data;
          setProfile(profile);
          disableLoadingData();
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get profile." });
        history.push("/profiles");
      });
  }, [profileId, disableLoadingData, history]);

  function saveProfile() {
    if (!profileId) {
      postProfile(profile)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Profile successfully created.",
            });
            history.push("/profiles");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save profile.",
          });
        });
    } else {
      updateProfile(profileId, profile)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Changes successfully saved.",
            });
            history.push("/profiles");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save changes.",
          });
        });
    }
  }

  const handleChange = (element) => (event) => {
    setProfile({ ...profile, [element]: event.target.value });
  };

  const handleChangeEditor = (element, value) => {
    setProfile({ ...profile, [element]: value });
  };

  if (user?.type === "admin")
    if (isLoadingData) return <ContentSkeleton />;
    else
      return (
        <>
          <Card>
            <CardHeader title="Edit profile"></CardHeader>
            <CardBody>
              <TextField
                id={`type`}
                label="Type"
                value={profile.type}
                onChange={handleChange("type")}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="outlined"
                required
              />
              <TextField
                id={"desc"}
                label="Description"
                value={profile.description}
                onChange={handleChange("description")}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="outlined"
                required
                placeholder="Enter profile description here..."
              />
              <FormControl style={{ width: "100%" }} className="mb-4 mt-4">
                <InputLabel id="demo-simple-select-standard-label">
                  Profile permissions
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={profile.permissions}
                  onChange={handleChange("permissions")}
                  multiple
                  MenuProps={MenuProps}
                >
                  <MenuItem key="excelAccess" value="excelAccess">
                    Accés a excel
                  </MenuItem>
                  <MenuItem key="deleteAccess" value="deleteAccess">
                    Permís d'esborrar
                  </MenuItem>
                  <MenuItem key="adminAccess" value="adminAccess">
                    Accés als administradors
                  </MenuItem>
                </Select>
                <FormHelperText>Select Profile permissions </FormHelperText>
              </FormControl>
            </CardBody>
          </Card>
          <Button
            onClick={() => history.push("/profiles")}
            variant="outlined"
            style={{ marginRight: "20px" }}
          >
            Back
          </Button>
          <Button
            onClick={() => saveProfile()}
            variant="outlined"
            color="primary"
            style={{ marginRight: "20px" }}
          >
            Save Profile
          </Button>
          {profileId && (
            <>
              <MuiThemeProvider theme={theme}>
                <Button
                  onClick={() => setOpenConfirmDialog(true)}
                  variant="outlined"
                  color="secondary"
                >
                  Delete profile
                </Button>
              </MuiThemeProvider>

              <ConfirmDialog
                title={"Are you sure you want to delete this profile?"}
                open={openConfirmDialog}
                setOpen={setOpenConfirmDialog}
                onConfirm={() => {
                  deleteProfile(profileId)
                    .then((res) => {
                      if (res.status === 204) {
                        alertSuccess({
                          title: "Deleted!",
                          customMessage: "Profile deleted successfully",
                        });
                        history.push("/profiles");
                      }
                    })
                    .catch((error) => {
                      alertError({
                        error: error,
                        customMessage: "Could not delete profile.",
                      });
                    });
                }}
              />
            </>
          )}
        </>
      );
  else
    return (
      <>
        Sorry, you do not have access permissions. Ask an administrator to
        access this section
      </>
    );
}
