import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table, {
  dateFormatter,
  buttonsStyle,
} from "../../../components/tables/table";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { getAdvertisers, deleteAdvertiser } from "../../../../api/advertiser";
import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";
import { getCustomers } from "../../../../api/customer";
import { shallowEqual, useSelector } from "react-redux";
import { getProfileById } from "../../../../api/profile";
import { postLog } from "../../../../api/log";

function getData(advertisers, customers) {
  let data = [];
  for (let i = 0; i < advertisers.length; ++i) {
    const elem = {};
    elem.name = advertisers[i].fullName;
    elem.code = advertisers[i].code;
    elem.customer = advertisers[i].customer
      ? customers.find((cat) => cat._id === advertisers[i].customer)?.fullName
      : "---";
    elem.createdAt = advertisers[i].createdAt;
    elem.id = advertisers[i]._id;
    data = data.concat(elem);
  }
  return data;
}

export default function AdvertisersPage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const [data, setData] = useState([]);
  const [profile, setProfile] = useState("");
  const [advertiserId, setAdvertiserId] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const history = useHistory();

  function buttonFormatter(cell) {
    let deleteButton;
    if (user?.type === "admin" && profile === "Administrador")
      deleteButton = (
        <Tooltip title="Delete">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              setAdvertiserId(cell);
              setOpenConfirmDialog(true);
            }}
          >
            <DeleteIcon />
          </Button>
        </Tooltip>
      );

    return (
      <>
        <Tooltip title="Edit">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => history.push("/edit-advertiser/" + cell)}
          >
            <EditIcon />
          </Button>
        </Tooltip>
        {deleteButton}
      </>
    );
  }

  const columns = [
    { dataField: "code", text: "Code", sort: true },
    { dataField: "name", text: "Advertiser", sort: true },
    { dataField: "customer", text: "Customer", sort: true },
    {
      dataField: "createdAt",
      text: "Created at",
      formatter: dateFormatter,
      sort: true,
    },
    {
      dataField: "id",
      text: "",
      formatter: buttonFormatter,
      searchable: false,
    },
  ];

  useEffect(() => {
    getAdvertisers()
      .then((res) => {
        if (res.status === 200) {
          getCustomers()
            .then((resp) => {
              if (resp.status === 200) {
                postLog("advertiser-list", "visualize", user.fullName);
                setData(getData(res.data, resp.data));
                setRefresh(false);
              }
            })
            .catch((error) => {
              alertError({
                error: error,
                customMessage: "Could not get customers.",
              });
            });
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get advertisers.",
        });
      });
    if (user.profile) {
      getProfileById(user?.profile)
        .then((res) => {
          if (res.status === 200) {
            setProfile(res.data.type);
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not get user profile.",
          });
        });
    }
  }, [refresh]);

  return (
    <>
      <Card>
        <CardHeader title="Advertisers list">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => history.push("/edit-advertiser")}
            >
              Add new
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table data={data} columns={columns} />
          <ConfirmDialog
            title={"Are you sure you want to remove this advertiser?"}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteAdvertiser(advertiserId)
                .then((res) => {
                  if (res.status === 204) {
                    postLog("advertiser", "delete", user.fullName);
                    alertSuccess({
                      title: "Deleted!",
                      customMessage: "Advertiser removed successfully.",
                    });
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: "Could not remove advertiser.",
                  });
                });
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
