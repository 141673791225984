import { /* baseClient, */ authClient, API } from '../index'

// Get all advertisers
export const getAdvertisers = () => {
  return authClient().get(`${API}/advertiser`)
}

// Get advertiser by id
export const getAdvertiserById = (id) => {
  return authClient().get(`${API}/advertiser/${id}`)
}

// Delete advertiser
export const deleteAdvertiser = (id) => {
  return authClient().delete(`${API}/advertiser/${id}`)
}

// Create advertiser
export const postAdvertiser = (advertiser) => {
  return authClient().post(`${API}/advertiser`, advertiser)
}

// Update advertiser
export const updateAdvertiser = (id, advertiser) => {
  return authClient().put(`${API}/advertiser/${id}`, advertiser)
}
