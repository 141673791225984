import React, { useState, useEffect } from "react";
import { getUserById } from "../../api/user";
import { alertError } from "../../utils/logger";
import { dateFormatter } from "./tables/table";

export default function AuthorFooter({
  userAdded,
  userModified,
  dateCreated,
  dateModified,
  entity,
}) {
  const [createdBy, setCreatedBy] = useState(null);
  const [modifiedBy, setModifiedBy] = useState(null);

  useEffect(() => {
    if (userAdded) {
      getUserById(userAdded)
        .then((resp) => {
          setCreatedBy(resp.data.fullName);
        })
        .catch((error) => {
          //alertError({ error: error, customMessage: `Could not get user who created the ${entity}.` })
        });
    }
    if (userModified) {
      getUserById(userModified)
        .then((resp) => {
          setModifiedBy(resp.data.fullName);
        })
        .catch((error) => {
          //alertError({ error: error, customMessage: `Could not get user who last modified the ${entity}.` })
        });
    }
  }, [userAdded, userModified, entity]);

  return (
    <div className="mt-3">
      <p style={{ textAlign: "end" }}>
        <em>
          <strong>Created by: </strong>
          {createdBy || "-----"}
          {dateCreated ? ` (${dateFormatter(dateCreated)})` : ""}
        </em>
      </p>
      <p style={{ textAlign: "end" }}>
        <em>
          <strong>Last modified by: </strong>
          {modifiedBy || "------"}
          {dateModified ? ` (${dateFormatter(dateModified)})` : ""}
        </em>
      </p>
    </div>
  );
}
