import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import Table, {
  dateFormatter,
  buttonsStyle,
} from "../../../components/tables/table";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { getCustomers, deleteCustomer } from "../../../../api/customer";
import { Button, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { alertError, alertSuccess } from "../../../../utils/logger";
import { useHistory } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { getProfileById } from "../../../../api/profile";
import { postLog } from "../../../../api/log";

function getData(customers) {
  let data = [];
  for (let i = 0; i < customers.length; ++i) {
    const elem = {};
    elem.name = customers[i].fullName;
    elem.code = customers[i].code;
    elem.createdAt = customers[i].createdAt;
    elem.id = customers[i]._id;
    data = data.concat(elem);
  }
  return data;
}

export default function CustomersPage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const [data, setData] = useState([]);
  const [profile, setProfile] = useState("");
  const [customerId, setCustomerId] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const history = useHistory();

  function buttonFormatter(cell) {
    let deleteButton;
    if (user?.type === "admin" && profile === "Administrador")
      deleteButton = (
        <Tooltip title="Delete">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => {
              setCustomerId(cell);
              setOpenConfirmDialog(true);
            }}
          >
            <DeleteIcon />
          </Button>
        </Tooltip>
      );

    return (
      <>
        <Tooltip title="Edit">
          <Button
            style={buttonsStyle}
            size="small"
            onClick={() => history.push("/edit-customer/" + cell)}
          >
            <EditIcon />
          </Button>
        </Tooltip>
        {deleteButton}
      </>
    );
  }

  useEffect(() => {
    if (user.profile) {
      getProfileById(user?.profile)
        .then((res) => {
          if (res.status === 200) {
            setProfile(res.data.type);
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not get user profile.",
          });
        });
    }
    getCustomers()
      .then((res) => {
        if (res.status === 200) {
          postLog("customer-list", "visualize", user.fullName);
          setData(getData(res.data));
          setRefresh(false);
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get customers." });
      });
  }, [refresh]);

  const columns = [
    { dataField: "code", text: "Code", sort: true },
    { dataField: "name", text: "Customer", sort: true },
    {
      dataField: "createdAt",
      text: "Created at",
      formatter: dateFormatter,
      sort: true,
    },
    {
      dataField: "id",
      text: "",
      formatter: buttonFormatter,
      searchable: false,
    },
  ];
  return (
    <>
      <Card>
        <CardHeader title="Customers list">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => history.push("/edit-customer")}
            >
              Add new
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table data={data} columns={columns} />
          <ConfirmDialog
            title={"Are you sure you want to remove this customer?"}
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            onConfirm={() => {
              deleteCustomer(customerId)
                .then((res) => {
                  if (res.status === 204) {
                    postLog("customer", "delete", user.fullName);
                    alertSuccess({
                      title: "Deleted!",
                      customMessage: "Customer removed successfully.",
                    });
                    setRefresh(true);
                  }
                })
                .catch((error) => {
                  alertError({
                    error: error,
                    customMessage: "Could not remove customer.",
                  });
                });
            }}
          />
        </CardBody>
      </Card>
    </>
  );
}
