import { /* baseClient, */ authClient, API } from '../index'

// Get all customers
export const getCustomers = () => {
  return authClient().get(`${API}/customer`)
}

// Get customer by id
export const getCustomerById = (id) => {
  return authClient().get(`${API}/customer/${id}`)
}

// Delete customer
export const deleteCustomer = (id) => {
  return authClient().delete(`${API}/customer/${id}`)
}

// Create customer
export const postCustomer = (customer) => {
  return authClient().post(`${API}/customer`, customer)
}

// Update customer
export const updateCustomer = (id, customer) => {
  return authClient().put(`${API}/customer/${id}`, customer)
}
