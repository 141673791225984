import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import {
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteUser,
  getUserById,
  postUser,
  updateUser,
} from "../../../../api/user";
import { getProfiles, getProfileById } from "../../../../api/profile";
import { useSkeleton } from "../../../hooks/useSkeleton";
import { alertError, alertSuccess } from "../../../../utils/logger";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import { shallowEqual, useSelector } from "react-redux";

// Create theme for delete button (red)
const theme = createMuiTheme({
  palette: {
    secondary: {
      main: "#F64E60",
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => null,
};

function getEmptyAdmin() {
  return {
    fullName: "",
    email: "",
    role: "admin",
    profile: "",
    password: "",
    repeatPassword: "",
  };
}

export default function EditAdminsPage() {
  const user = useSelector((store) => store.authentication?.user, shallowEqual);
  const [admin, setAdmin] = useState(getEmptyAdmin());
  const [profile, setProfile] = useState("");
  const [profiles, setProfiles] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const adminId = useParams().id;
  const history = useHistory();

  const {
    isLoading: isLoadingData,
    disableLoading: disableLoadingData,
    ContentSkeleton,
  } = useSkeleton();

  useEffect(() => {
    getProfiles()
      .then((res) => {
        if (res.status === 200) {
          console.log(res.data);
          setProfiles(res.data);
        }
      })
      .catch((error) => {
        alertError({
          error: error,
          customMessage: "Could not get profiles.",
        });
        history.push("/admins");
      });
    if (!adminId) {
      disableLoadingData();
      return;
    }
    disableLoadingData();
    getUserById(adminId)
      .then((res) => {
        if (res.status === 200) {
          const user = res.data;
          delete user.password;
          setAdmin(user);
          disableLoadingData();
          //AQUI DINS OBTENIR TAMBÉ QUIN ÉS EL PERFIL QUE TÉ ASSIGNAT L'USUARI
          // getProfileById(user.profile).then(resp => {
          //   if(res.status === 200) {
          //     setProfile(resp.data.type)
          //   }
          // }).catch(error => {
          //   alertError({ error: error, customMessage: 'Could not get admins profile.' })
          //   history.push('/admins')
          // })
        }
      })
      .catch((error) => {
        alertError({ error: error, customMessage: "Could not get usuari." });
        history.push("/admins");
      });
  }, [adminId, disableLoadingData, history]);

  function saveAdmin() {
    if (admin.password !== admin.repeatPassword) {
      alertError({ error: null, customMessage: "Passwords do not match." });
      return;
    }
    delete admin.repeatPassword;
    if (!adminId) {
      postUser(admin)
        .then((res) => {
          if (res.status === 201) {
            alertSuccess({
              title: "Saved!",
              customMessage: "User successfully created.",
            });
            history.push("/admins");
          }
        })
        .catch((error) => {
          alertError({ error: error, customMessage: "Could not save usuari." });
        });
    } else {
      updateUser(adminId, admin)
        .then((res) => {
          if (res.status === 200) {
            alertSuccess({
              title: "Saved!",
              customMessage: "Changes successfully saved.",
            });
            history.push("/admins");
          }
        })
        .catch((error) => {
          alertError({
            error: error,
            customMessage: "Could not save changes.",
          });
        });
    }
  }

  const handleChange = (element) => (event) => {
    setAdmin({ ...admin, [element]: event.target.value });
  };

  if (user?.type === "admin")
    if (isLoadingData) return <ContentSkeleton />;
    else
      return (
        <>
          <Card>
            <CardHeader title="Edit user"></CardHeader>
            <CardBody>
              <TextField
                id={`fullName`}
                label="User"
                value={admin.fullName}
                onChange={handleChange("fullName")}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="outlined"
                required
              />
              <TextField
                id={`email`}
                label="Email"
                value={admin.email}
                onChange={handleChange("email")}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
                variant="outlined"
                required
              />
              <FormControl style={{ width: "100%" }} className="mb-4">
                <InputLabel id="demo-simple-select-standard-label">
                  User Profile
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={admin.profile || ""}
                  onChange={handleChange("profile")}
                  MenuProps={MenuProps}
                >
                  {profiles?.map((p, index) => {
                    return (
                      <MenuItem key={index} value={p._id}>
                        {p.type}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>Select an User Profile</FormHelperText>
              </FormControl>
              <TextField
                id={`password`}
                label="Password"
                value={admin.password}
                onChange={handleChange("password")}
                InputLabelProps={{
                  shrink: true,
                }}
                type="password"
                margin="normal"
                variant="outlined"
                required
              />
              <TextField
                id={`repeatPassword`}
                label="Repeat password"
                value={admin.repeatPassword}
                onChange={handleChange("repeatPassword")}
                InputLabelProps={{
                  shrink: true,
                }}
                type="password"
                margin="normal"
                variant="outlined"
                required
              />
            </CardBody>
          </Card>
          <Button
            onClick={() => history.push("/admins")}
            variant="outlined"
            style={{ marginRight: "20px" }}
          >
            Back
          </Button>
          <Button
            onClick={() => saveAdmin()}
            variant="outlined"
            color="primary"
            style={{ marginRight: "20px" }}
          >
            Save user
          </Button>
          {adminId && (
            <>
              <MuiThemeProvider theme={theme}>
                <Button
                  onClick={() => setOpenConfirmDialog(true)}
                  variant="outlined"
                  color="secondary"
                >
                  Delete user
                </Button>
              </MuiThemeProvider>

              <ConfirmDialog
                title={"Are you sure you want to delete this usuari?"}
                open={openConfirmDialog}
                setOpen={setOpenConfirmDialog}
                onConfirm={() => {
                  deleteUser(adminId)
                    .then((res) => {
                      if (res.status === 204) {
                        alertSuccess({
                          title: "Deleted!",
                          customMessage: "User deleted successfully",
                        });
                        history.push("/admins");
                      }
                    })
                    .catch((error) => {
                      alertError({
                        error: error,
                        customMessage: "Could not delete usuari.",
                      });
                    });
                }}
              />
            </>
          )}
        </>
      );
  else
    return (
      <>
        Sorry, you do not have access permissions. Ask an administrator to
        access this section
      </>
    );
}
